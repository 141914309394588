import { h, JSX } from 'preact';
import { ProgressiveThumbnail } from '../../shared/ProgressiveThumbnail.jsx';

interface PreloadThumbnailProps {
  height: number;
  mediaId: string;
  playerBorderRadius: number;
  swatchEnabled: boolean;
  thumbnailAltText: string | undefined;
  thumbnailAssets: object;
  transparentLetterbox: boolean | undefined;
}

export const PreloadThumbnail = ({
  height,
  mediaId,
  playerBorderRadius,
  swatchEnabled,
  thumbnailAltText,
  thumbnailAssets,
  transparentLetterbox,
}: PreloadThumbnailProps): JSX.Element => {
  return (
    <ProgressiveThumbnail
      backgroundColor={transparentLetterbox ? 'transparent' : undefined}
      images={thumbnailAssets}
      isVisible={true}
      hashedId={mediaId}
      playerBorderRadius={playerBorderRadius}
      swatchEnabled={swatchEnabled}
      uiHasRendered={false}
      thumbnailAltText={thumbnailAltText}
      height={`${height}px`}
    />
  );
};
