import { h, JSX } from 'preact';
import { standardSvgAttrs } from '../../../../../../../utilities/svg_boilerplate.js';

interface BigPlayButtonSVGProps {
  height: number;
  scale: number;
  width: number;
}

// TODO: This file can be deleted once rounded icons are fully released
export const OldBigPlayButtonSVG = ({
  width,
  height,
  scale,
}: BigPlayButtonSVGProps): JSX.Element => {
  const scaledHeight = height * scale;
  const svgAttrs = standardSvgAttrs({
    width,
    height,
    styleOverride: { position: 'absolute', height: `${scaledHeight}px` },
    ariaHidden: true,
  }) as JSX.SVGAttributes<SVGSVGElement>;

  return (
    <svg {...svgAttrs} alt="">
      <rect fill-rule="evenodd" clip-rule="evenodd" fill="none" width={width} height={height} />
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" points="53,22 53,58 79,40" />
    </svg>
  );
};
