import { h, JSX } from 'preact';
import { standardSvgAttrs } from '../../../../../../../utilities/svg_boilerplate.js';

interface RoundedSettingsButtonProps {
  onMouseEnter: () => void;
}

export const RoundedSettingsButton = ({
  onMouseEnter,
}: RoundedSettingsButtonProps): JSX.Element => {
  const svgAttrs = standardSvgAttrs({
    width: 40,
    height: 34,
    ariaHidden: true,
  }) as JSX.SVGAttributes<SVGSVGElement>;

  return (
    <svg {...svgAttrs} onMouseEnter={onMouseEnter}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4 15.4H28.3C28.7 15.4 29 15.7 29 16.1V16.7C29 17.1 28.7 17.4 28.3 17.4H26.4C26 17.4 25.6 17.7 25.5 18.1L25.1 19.2C25 19.5 25 20 25.3 20.3L26.6 21.6C26.9 21.9 26.9 22.3 26.6 22.6L26.2 23C25.9 23.3 25.5 23.3 25.2 23L23.9 21.7C23.6 21.5 23.1 21.4 22.8 21.6L21.7 22.1C21.3 22.2 21 22.6 21 23V24.7C21 25.1 20.7 25.4 20.3 25.4H19.7C19.3 25.4 19 25.1 19 24.7V23C19 22.6 18.7 22.2 18.3 22.1L17.1 21.6C16.8 21.5 16.3 21.5 16 21.8L14.8 23C14.5 23.3 14.1 23.3 13.8 23L13.4 22.6C13.1 22.3 13.1 21.9 13.4 21.6L14.6 20.4C14.8 20.1 14.9 19.6 14.7 19.3L14.2 18.1C14.1 17.7 13.7 17.4 13.3 17.4H11.7C11.3 17.4 11 17.1 11 16.7V16.1C11 15.7 11.3 15.4 11.7 15.4H13.3C13.7 15.4 14.1 15.1 14.2 14.7L14.7 13.5C14.9 13.2 14.9 12.7 14.6 12.4L13.4 11.2C13.1 10.9 13.1 10.5 13.4 10.2L13.8 9.8C14.1 9.5 14.5 9.5 14.8 9.8L16 11C16.3 11.3 16.8 11.4 17.1 11.2L18.3 10.7C18.7 10.6 19 10.2 19 9.8V8.1C19 7.7 19.3 7.4 19.7 7.4H20.3C20.7 7.4 21 7.7 21 8.1V9.8C21 10.2 21.3 10.6 21.7 10.7L22.8 11.2C23.1 11.4 23.6 11.4 23.9 11.1L25.2 9.8C25.5 9.5 25.9 9.5 26.2 9.8L26.6 10.2C26.9 10.5 26.9 10.9 26.6 11.2L25.3 12.5C25 12.8 24.9 13.3 25.1 13.6L25.5 14.7C25.6 15.1 26 15.4 26.4 15.4ZM19.9 20.4C22 20.4 23.8 18.7 23.8 16.5C23.8 14.3 22.1 12.6 19.9 12.6C17.7 12.6 16 14.4 16 16.5C16 18.6 17.7 20.4 19.9 20.4Z"
        fill="white"
      />
    </svg>
  );
};
